import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/Layout';

export default function ThankYou() {
  const data = useStaticQuery(graphql`
    query QueryThankYou {
      thankyou: file(relativePath: { eq: "thank-you.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Layout callBtn={false}>
      <div>
        <div className="">
          <div className="relative bg-gray-50">
            <main className="lg:relative">
              <div className="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left">
                <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
                  <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl lg:text-5xl xl:text-6xl">
                    <span className="mr-1">
                      {' '}
                      <span className="text-primarycolor">Merci</span> de nous
                      avoir contactés
                    </span>
                  </h2>
                  <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
                    Nous traitons en ce moment votre demande, un de nos
                    collaborateurs va vous répondre dans quelques minutes.
                  </p>
                  <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
                    Entre-temps n’hésitez pas à consulter nos :
                  </p>
                  <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
                    <div className="rounded-md shadow">
                      <Link
                        className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                        to="/blog"
                      >
                        Articles de blog
                      </Link>
                    </div>
                    <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                      <Link
                        className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-indigo-600 bg-white hover:text-indigo-500 focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                        to="/faq"
                      >
                        FAQ
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
                <Img
                  className="absolute inset-0 w-full h-full object-cover"
                  fluid={data.thankyou.childImageSharp.fluid}
                  alt=""
                />
              </div>
            </main>
          </div>
        </div>
      </div>
    </Layout>
  );
}
